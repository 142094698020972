import { transformListSelect } from "utils/utils";

export const LawNorms = [
  { value: "1", label: "Lei" },
  { value: "2", label: "Lei Complementar" },
  { value: "3", label: "Decreto" },
  { value: "4", label: "Ato" },
  { value: "5", label: "Ato Normativo" },
  { value: "19", label: "Boletim Informativo" },
  { value: "6", label: "Parecer" },
  { value: "7", label: "Convenção" },
  { value: "8", label: "Emenda" },
  { value: "9", label: "Solução de consulta" },
  { value: "10", label: "Resolução" },
  { value: "11", label: "Instrução Normativa" },
  { value: "12", label: "Portaria" },
  { value: "13", label: "Portaria Conjuta" },
  { value: "14", label: "Portaria Normativa" },
  { value: "15", label: "Nota explicativa" },
  { value: "16", label: "Comunicado" },
  { value: "17", label: "Orientação" },
  { value: "18", label: "Resposta à consulta" },
];

export const LAW_STATUS = {
  CLONE: "clone",
  ACTIVE: "active",
  INACTIVE: "inactive",
  DRAFT: "draft",
  DELETED: "deleted",
};

export const LawStatesCbenef = ["PR", "RJ", "RS", "DF"];

export const MVAS_TYPES = {
  Selecione: "Selecione",
  "Preço Final máximo ou tabelado": "Preço Final máximo ou tabelado",
  "MVA %)": "MVA %)",
  "Pauta - Valor": "Pauta - Valor",
};

export const MVAS_TYPES_LIST = transformListSelect(MVAS_TYPES);
