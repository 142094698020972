// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "redux/store";
import { Provider } from "react-redux";

import "chart.js/auto";

// ** ThemeColors Context

import { ThemeContext } from "./theme/vuex/src/utility/context/ThemeColors";

import ability from "./theme/vuex/src/configs/acl/ability";
import { AbilityContext } from "./theme/vuex/src/utility/context/Can";

// ** ThemeConfig
import themeConfig from "./theme/vuex/src/configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Spinner (Splash Screen)
import Spinner from "./theme/vuex/src/@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./theme/vuex/src/@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** Import Skeleton
import "react-loading-skeleton/dist/skeleton.css";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./theme/vuex/src/@core/assets/fonts/feather/iconfont.css";
import "./theme/vuex/src/@core/scss/core.scss";
import "./theme/vuex/src/assets/scss/style.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";

// ** Service Worker
import * as serviceWorker from "./theme/vuex/src/serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
